import React, { Component } from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';
import { TestimonialGrid, TestimonialCard } from '../../components/TestimonialGrid'

import IntelligentPhoneNumbers from '../images/intelligentPhoneNumbers.png'
import SfHalf from '../images/sfHalf.png'
import TeamDashHalf from '../images/teamDashHalf.svg'
import LibertyRing from '../animations/libertyRing.json'


var lottie

export default class BetterSalesAnalytics extends Component {
  constructor(props) {
    super(props)
    this.animationIsAttached = false
  }

  componentDidMount() {
    lottie = require('lottie-web')
    this.attachAnimation()
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: LibertyRing,
      }

      lottie.loadAnimation(animationProperties)
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <title>Truly | Unified Sales Analytics</title>
        </Helmet>

        <WrapperBackground
          color={colors.trulyDark}
          background='telephony1'
        >
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
              Unified Sales Analytics
              </PageTitle2>
              <PageTitle as="p" color={colors.white} mb="1.5rem" center>
              Finally, You Can Get The Full Picture Of Your Funnel In Salesforce
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperBackground center color={colors.trulyDark} background="managedByQ" gradientType="blueToGreen">
          <Section background={colors.trulyDark} flexDirection="column" maxWidth="100%">
            <Box>
                <PageTitle2 color={colors.white} mb="2rem" mt="2rem">
                Trusted By Category Defining Sales<br />& Customer Success Teams
              </PageTitle2>
            </Box>
            <TestimonialGrid>
              <TestimonialCard avatar="jackie" name="Jackie F" company="NewFront Insurance" quote="I love how you are able to listen to other colleagues calls while training. That live feature helped me significantly when I was training. I also like that you can see who is actively on a call."/>
              <TestimonialCard avatar="taylor" name="Taylor S" company="Cloud Kitchens" quote="Truly is great because it easily integrates with Salesforce to track my activity - both calls and texts. I also love the voicemail drop feature, as well as the mobile app that syncs flawlessly with my iPhone."/>
              <TestimonialCard avatar="billy" name="Billy B" company="Galvaninze" quote="I love how I can switch my number based on the purpose of my call and how easy Truly is to use. It's awesome I can listen to any of my colleagues phone calls at any time. It was a tremendous learning tool for me in my new job. The voicemail drop function is a game changer."/>
            </TestimonialGrid>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection={['column', 'row']} py={['6rem', '0rem']} background={colors.trulyDark}>
            <Box width={[1/2, 1/3]}>
              <CenteredImg src={IntelligentPhoneNumbers} alt="Intelligent Phone Numbers"/>
            </Box>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem">
                Fix The Salesforce Data Model
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                The core Salesforce data model wasn't built for the type of Revenue Analytics that today's leaders need.  Our Salesforce Package upgrades the data model to make it possible to run all of your current Excel/BI based analyses natively in CRM, and in real time.
              </Large>
            </Box>
          </Section>
        </WrapperSolid>

        <WrapperSolid color='#292929'>
          <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
              Rich Dashboards With Drilldowns.
              </Title3>
              <Large as="p" color={colors.white} mb="1.5rem">
              No longer do you need to jump between reports to get what you want.  Our dashboards allow you to drill down from the company >> to the team >> to the funnel conversion event >> to the activity so you understand EXACTLY what happened in a single report.
              </Large>
            </Box>
            <Box width={[1/2, 1/2]} m="32pt">
              <CenteredImg src={SfHalf} alt="Salesforce"/>
            </Box>
          </Section>
        </WrapperSolid>

        <WrapperSolid>
          <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '0rem']}>
            <Box width={[1/2, 1/2]} pb="2rem">
              <CenteredImg src={TeamDashHalf} alt="Team Dashboard"/>
            </Box>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
              GORGEOUS VISUALIZATIONS
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
              Truly's reporting is built on a market leading BI platform, giving you unparalleled visibility at scale. Explore trends, then drill down to teams and reps to get the full picture.
              </Large>
            </Box>
          </Section>
        </WrapperSolid>
      </Default>
    )
  }
}

const CenteredImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`
